<template>
  <div>
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="kt-user-card__avatar">
        <img
          class="kt-hidden"
          alt="Pic"
          src="@/assets/media/users/300_25.jpg"
        />
        <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
        <span
          class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
          >{{ firstCharOfName }}</span
        >
      </div>
      <div class="kt-user-card__name">{{ user.name }}</div>
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
        <div class="kt-notification__custom kt-space-between">
          <a
            v-on:click="editProfile(user.sub)"
            class="btn btn-label btn-label-brand btn-sm btn-bold"
            >Edit Profile</a
          >
          <a
            v-on:click="onLogout()"
            class="btn btn-label btn-label-brand btn-sm btn-bold"
            >Sign Out</a
          >
        </div>
    </div>
   
    <!--end: Navigation -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "KTDropdownUser",
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    editProfile(id) {
      this.$router.push({ name: "EditProfile", params: { id } });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    },
    firstCharOfName() {
      return this.user.name ? this.user.name.substr(0, 1) : "";
    },
  },
};
</script>
