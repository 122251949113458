<template>
  <li class="kt-menu__section"
      v-if="user.role == 'User' && menu.section == 'Report'">
    <h4 class="kt-menu__section-text">
      {{ menu.translate ? $t(menu.translate) : menu.section }}
    </h4>
    <i class="kt-menu__section-icon flaticon-more-v2"></i>
  </li>
  <li class="kt-menu__section"
      v-else-if="user.role == 'super_admin' 
      && (menu.section == 'Admin' 
      || menu.section == 'Master'
      || menu.section == 'PDPA'
      || menu.section == 'Member Card'
      || menu.section == 'Report')">
    <h4 class="kt-menu__section-text">
      {{ menu.translate ? $t(menu.translate) : menu.section }}
    </h4>
    <i class="kt-menu__section-icon flaticon-more-v2"></i>
  </li>
  <li class="kt-menu__section"
      v-else-if="user.role == 'Administrator' 
      && (menu.section == 'Master'
      || menu.section == 'PDPA'
      || menu.section == 'Member Card'
      || menu.section == 'Report')">
    <h4 class="kt-menu__section-text">
      {{ menu.translate ? $t(menu.translate) : menu.section }}
    </h4>
    <i class="kt-menu__section-icon flaticon-more-v2"></i>
  </li>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "KTMenuSection",
  components: {},
  props: {
    menu: Object,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  }
};
</script>
