var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user.role == 'User' && _vm.menu.section == 'Report')?_c('li',{staticClass:"kt-menu__section"},[_c('h4',{staticClass:"kt-menu__section-text"},[_vm._v(" "+_vm._s(_vm.menu.translate ? _vm.$t(_vm.menu.translate) : _vm.menu.section)+" ")]),_c('i',{staticClass:"kt-menu__section-icon flaticon-more-v2"})]):(_vm.user.role == 'super_admin' 
    && (_vm.menu.section == 'Admin' 
    || _vm.menu.section == 'Master'
    || _vm.menu.section == 'PDPA'
    || _vm.menu.section == 'Member Card'
    || _vm.menu.section == 'Report'))?_c('li',{staticClass:"kt-menu__section"},[_c('h4',{staticClass:"kt-menu__section-text"},[_vm._v(" "+_vm._s(_vm.menu.translate ? _vm.$t(_vm.menu.translate) : _vm.menu.section)+" ")]),_c('i',{staticClass:"kt-menu__section-icon flaticon-more-v2"})]):(_vm.user.role == 'Administrator' 
    && (_vm.menu.section == 'Master'
    || _vm.menu.section == 'PDPA'
    || _vm.menu.section == 'Member Card'
    || _vm.menu.section == 'Report'))?_c('li',{staticClass:"kt-menu__section"},[_c('h4',{staticClass:"kt-menu__section-text"},[_vm._v(" "+_vm._s(_vm.menu.translate ? _vm.$t(_vm.menu.translate) : _vm.menu.section)+" ")]),_c('i',{staticClass:"kt-menu__section-icon flaticon-more-v2"})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }