<template>
  <router-link
    :to="link()"
    :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link"
    v-if="user.role == 'User' 
    && (menu.title == 'Dashboard'
    || menu.title == 'Report Application'
    || menu.title == 'Report Bell Point'
    || menu.title == 'Report Customer Detail'
    || menu.title == 'Report Member Card'
    || menu.title == 'Report Use Member Card'
    || menu.title == 'Report Use Bell Point'
    || menu.title == 'Report Rank Bell Point'
    || menu.title == 'Report Use Promotion Birth Date')"
  >
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i
      v-if="!menu.icon && !menu.root"
      class="kt-menu__link-bullet"
      :class="bullet()"
      ><span></span
    ></i>
    <span class="kt-menu__link-text">{{
      menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
  </router-link>
  <router-link
    :to="link()"
    :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link"
    v-else-if="user.role == 'Administrator'
    && (menu.title == 'Dashboard'
    || menu.title == 'Master'
    || menu.title == 'User'
    || menu.title == 'Branch'
    || menu.title == 'HomepageBanner'
    || menu.title == 'TermandPrivacy'
    || menu.title == 'ContactUs'
    || menu.title == 'SplashScreen'
    || menu.title == 'ManageNotification'
    || menu.title == 'Promotion'
    || menu.title == 'Menu'
    || menu.title == 'PDPA'
    || menu.title == 'Search Member Card'
    || menu.title == 'Generate Member Card'
    || menu.title == 'Manage Member Card'
    || menu.title == 'Report Application'
    || menu.title == 'Report Bell Point'
    || menu.title == 'Report Customer Detail'
    || menu.title == 'Report Member Card'
    || menu.title == 'Report Use Member Card'
    || menu.title == 'Report Use Bell Point'
    || menu.title == 'Report Rank Bell Point'
    || menu.title == 'Report Use Promotion Birth Date')">
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i
      v-if="!menu.icon && !menu.root"
      class="kt-menu__link-bullet"
      :class="bullet()"
      ><span></span
    ></i>
    <span class="kt-menu__link-text">{{
      menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
  </router-link>
  <router-link
    :to="link()"
    :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link"
    v-else-if="user.role == 'super_admin'">
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i
      v-if="!menu.icon && !menu.root"
      class="kt-menu__link-bullet"
      :class="bullet()"
      ><span></span
    ></i>
    <span class="kt-menu__link-text">{{
      menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
  </router-link>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "KTMenuItemText",
  components: {},
  props: {
    menu: Object,
    parentMenu: Object
  },
  methods: {
    /**
     * Get bullet class from config
     * @returns {string} class name
     */
    bullet: function() {
      // get the default bullet
      if (!this.menu.bullet) {
        this.menu.bullet = "dot";
      }
      // bullet class name
      return `kt-menu__link-bullet--${this.menu.bullet}`;
    },
    /**
     * Get current menu link path
     * @returns {{}|{path: string}}
     */
    link: function() {
      // check if no link in this menu item
      if (!this.menu.page) {
        return {};
      }
      // pass the link prepend with slash
      return { path: `/${this.menu.page}` };
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  }
};
</script>
