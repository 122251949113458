var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user.role == 'User' 
  && (_vm.menu.title == 'Dashboard'
  || _vm.menu.title == 'Report Application'
  || _vm.menu.title == 'Report Bell Point'
  || _vm.menu.title == 'Report Customer Detail'
  || _vm.menu.title == 'Report Member Card'
  || _vm.menu.title == 'Report Use Member Card'
  || _vm.menu.title == 'Report Use Bell Point'
  || _vm.menu.title == 'Report Rank Bell Point'
  || _vm.menu.title == 'Report Use Promotion Birth Date'))?_c('router-link',{staticClass:"kt-menu__link",class:{ 'kt-menu__toggle': _vm.menu.submenu },attrs:{"to":_vm.link()}},[(_vm.menu.icon)?_c('i',{staticClass:"kt-menu__link-icon",class:_vm.menu.icon}):_vm._e(),(!_vm.menu.icon && !_vm.menu.root)?_c('i',{staticClass:"kt-menu__link-bullet",class:_vm.bullet()},[_c('span')]):_vm._e(),_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(_vm.menu.translate ? _vm.$t(_vm.menu.translate) : _vm.menu.title))]),(_vm.menu.submenu)?_c('i',{staticClass:"kt-menu__ver-arrow la la-angle-right"}):_vm._e()]):(_vm.user.role == 'Administrator'
  && (_vm.menu.title == 'Dashboard'
  || _vm.menu.title == 'Master'
  || _vm.menu.title == 'User'
  || _vm.menu.title == 'Branch'
  || _vm.menu.title == 'HomepageBanner'
  || _vm.menu.title == 'TermandPrivacy'
  || _vm.menu.title == 'ContactUs'
  || _vm.menu.title == 'SplashScreen'
  || _vm.menu.title == 'ManageNotification'
  || _vm.menu.title == 'Promotion'
  || _vm.menu.title == 'Menu'
  || _vm.menu.title == 'PDPA'
  || _vm.menu.title == 'Search Member Card'
  || _vm.menu.title == 'Generate Member Card'
  || _vm.menu.title == 'Manage Member Card'
  || _vm.menu.title == 'Report Application'
  || _vm.menu.title == 'Report Bell Point'
  || _vm.menu.title == 'Report Customer Detail'
  || _vm.menu.title == 'Report Member Card'
  || _vm.menu.title == 'Report Use Member Card'
  || _vm.menu.title == 'Report Use Bell Point'
  || _vm.menu.title == 'Report Rank Bell Point'
  || _vm.menu.title == 'Report Use Promotion Birth Date'))?_c('router-link',{staticClass:"kt-menu__link",class:{ 'kt-menu__toggle': _vm.menu.submenu },attrs:{"to":_vm.link()}},[(_vm.menu.icon)?_c('i',{staticClass:"kt-menu__link-icon",class:_vm.menu.icon}):_vm._e(),(!_vm.menu.icon && !_vm.menu.root)?_c('i',{staticClass:"kt-menu__link-bullet",class:_vm.bullet()},[_c('span')]):_vm._e(),_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(_vm.menu.translate ? _vm.$t(_vm.menu.translate) : _vm.menu.title))]),(_vm.menu.submenu)?_c('i',{staticClass:"kt-menu__ver-arrow la la-angle-right"}):_vm._e()]):(_vm.user.role == 'super_admin')?_c('router-link',{staticClass:"kt-menu__link",class:{ 'kt-menu__toggle': _vm.menu.submenu },attrs:{"to":_vm.link()}},[(_vm.menu.icon)?_c('i',{staticClass:"kt-menu__link-icon",class:_vm.menu.icon}):_vm._e(),(!_vm.menu.icon && !_vm.menu.root)?_c('i',{staticClass:"kt-menu__link-bullet",class:_vm.bullet()},[_c('span')]):_vm._e(),_c('span',{staticClass:"kt-menu__link-text"},[_vm._v(_vm._s(_vm.menu.translate ? _vm.$t(_vm.menu.translate) : _vm.menu.title))]),(_vm.menu.submenu)?_c('i',{staticClass:"kt-menu__ver-arrow la la-angle-right"}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }